///////////////////////////////////////////////////////////
// Mixins and Functions                                  //
///////////////////////////////////////////////////////////
@import './variables';

// Breakpoints Function
@function breakpoints($size) {
  @return map-get($breakpoints, $size);
}

// Ellipsis
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
