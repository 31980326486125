///////////////////////////////////////////////////////////
// Main Footer                               (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.main_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0;
  padding: 0;
  width: 100%;
  @media (max-width: breakpoints(large)) {
    display: block;
    justify-content: unset;
  }
}

.footer_left {
  padding: 15px;
  background-color: #e2e3e4;

  @media (min-width: breakpoints(large)) {
    padding: 20px 0;
    background-color: #e2e3e4;
  }

  .footer_menu {
    padding-top: 20px;
    padding-bottom: 10px;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: transparent;

    li {
      margin: 0 3rem 0 0;
      padding: 1rem 0;
      width: auto;
      border: none;
    }

    a {
      font-size: 14px;
      font-weight: $medium;
      line-height: 18px;
      color: #53565a;
      text-decoration: none;
    }

    @media (max-width: breakpoints(large)) {
      flex-direction: column;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;

      li {
        padding-bottom: 0;
      }

      a {
        padding: 6px 0;
        color: $black;
      }
    }

    @media (max-width: breakpoints(medium)) {
      li:nth-child(1) {
        order: 4;
      }
      li:nth-child(3),
      li:nth-child(5) {
        margin: unset;
      }
    }
  }

  .copyright_text {
    color: $blue-super-dark-grayish;
    font-size: 12px;
    line-height: 16px;
    font-weight: $regular;

    @media (max-width: breakpoints(large)) {
      margin-top: 32px;
      margin-right: 15px;
      font-size: 0.688rem;
      line-height: 16px;
      width: 100%;
      text-align: center;
      margin-bottom: 29px;
      .put_br_mobile::after {
        content: '\a';
        white-space: pre;
      }
    }
  }

  .secondary_nav {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    text-align: center;
    justify-content: flex-start;
    padding-inline-start: unset;

    @media (max-width: breakpoints(large)) {
      justify-content: center;
    }

    li {
      padding-left: 0;
      padding-right: 0;
      margin-right: 2rem;
      @media (max-width: breakpoints(large)) {
        margin-right: 2rem;
      }
    }

    a {
      color: #2360a0;
      font-size: 12px;
      font-weight: $regular;
      line-height: 16px;
      text-decoration: none;
    }
  }
}

.footer_right {
  width: 500px;
  background-color: #97999b;

  @media (max-width: breakpoints(large)) {
    width: unset;
    padding: unset;
  }

  .social_nav {
    list-style: none;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    @media (max-width: breakpoints(large)) {
      justify-content: space-around;
    }

    li a {
      display: flex;
      box-sizing: border-box;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin: 10px;
      align-items: center;
      text-align: center;
      svg {
        margin: 0 auto;
      }
      &:hover {
        background-color: $red;
      }
    }
  }
}
