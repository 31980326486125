///////////////////////////////////////////////////////////
// Main Header                               (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.header_nav {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  align-self: center;
}

.dashboard_nav {
  .logo_nav {
    // Mobile
    @media (max-width: breakpoints(large)) {
      flex-grow: 0;
      margin-left: 0;
    }
  }

  .partner_program_title {
    margin-top: 8px;
    margin-left: 10px;
    color: $red;
    // Mobile
    @media (max-width: breakpoints(large)) {
      display: none;
    }
  }

  .logged_in {
    margin-left: auto;
    p {
      color: $gray-dark;
    }
    svg path {
      fill: $gray-dark;
    }
  }
}

.logo_nav {
  // Mobile
  @media (max-width: breakpoints(large)) {
    flex-grow: 1;
    margin-left: -26px;
  }
  a {
    display: block;
    width: 205px;
    height: 47px;
    box-sizing: border-box;
    margin-right: 10px;
    background-image: url('../../../public/images/broadcom-logo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 205px auto;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    // Mobile
    @media (max-width: breakpoints(large)) {
      margin: 0 auto;
    }
  }
}
