///////////////////////////////////////////////////////////
// Globals                                               //
///////////////////////////////////////////////////////////
@import './variables';
@import './mixins.scss';

/* width */
::-webkit-scrollbar {
  width: 15px;
}

div {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

.rbc-event {
  background-color: transparent !important;
  border: none !important;
  min-width: 50% !important;
  pointer-events: none !important;
}

.rbc-month-row {
  overflow: unset !important;
}

.rbc-time-slot {
  background-color: #fafafb !important;
  .rbc-label {
    font-family: $font !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center !important;
    color: #8799ab !important;
  }
}

.rbc-event-label {
  display: none !important;
}

.rbc-timeslot-group,
.rbc-time-header-gutter,
.rbc-header {
  background-color: #fafafb !important;
}

.rbc-time-header-gutter {
  width: 77.6094px !important;
  min-width: 77.6094px !important;
  max-width: 77.6094px !important;
}

.rbc-timeslot-group {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rbc-row-segment {
  .rbc-button-link,
  .rbc-show-more {
    display: none !important;
  }
}

.rbc-time-header-cell {
  display: none !important;
}

.rbc-time-header-content {
  .rbc-row {
    display: none !important;
  }
}

.rbc-current-time-indicator {
  background-color: #2360a0 !important;
  height: 2px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
  border-left: 0.5px solid #e8e8e8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}

//borderleft e8e8e8

// Body ////////////////////////////////////////////////////
html {
  font-family: $font;
  font-size: 1rem;
}

.body {
  margin: 0;
  padding: 0;
  // -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font;
}

// Typography /////////////////////////////////////////////
h1 {
  font-family: $font;
  font-weight: $light;
  font-size: 2.125rem;
  font-style: normal;
  line-height: 2.5rem;
}

h2 {
  font-family: $font;
  font-weight: $medium;
  font-size: 1.375rem;
  font-style: normal;
}

h3 {
  font-family: $font;
  font-weight: $regular;
  font-size: 1.375rem;
  font-style: normal;
}

h4 {
  font-family: $font;
  font-weight: $regular;
  font-size: 1rem;
}

h5 {
  font-family: $font;
  font-weight: medium;
  font-size: 1rem;
  margin: 0 0 0.375rem 0;
}

h6 {
  font-family: $font;
  font-weight: $bold;
  font-size: 0.68rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

p {
  font-family: $font;
  font-weight: $regular;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: unset;
}

a {
  font-family: $font;
  font-weight: $regular;
  font-size: 0.75rem;
  line-height: 20px;
  color: $blue;
}

small {
  font-family: $font;
  font-weight: $regular;
  font-size: 0.75rem;
}

legend {
  @extend h4;
  padding: 0;
  margin: 0 0 0.5rem 0;
  color: $black;
  font-size: 1rem;
  line-height: 20px;
}

label {
  @extend p;
  line-height: 1.375rem;
}

.ellipsis {
  @include ellipsis;
}

// Form ////////////////////////////////////////////////////
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  input {
    width: 100%;
  }
  textarea {
    min-width: 100%;
    resize: vertical;
  }
  label {
    margin-bottom: 4px;
  }
  small {
    font-weight: 325;
    font-size: 14px;
    line-height: 1rem;
    color: #92929d;
  }
  &.error {
    input,
    select,
    textarea {
      background-color: $bg-fail;
      border: 1px solid $red;
      &:focus,
      &:active {
        outline: none;
        box-shadow: inset 0 0 0 1px $red;
      }
    }
    span {
      color: $red;
    }
  }
}

input,
textarea,
select {
  box-sizing: border-box;
  border: 1px solid $gray-light-inputs;
  border-radius: 4px;
  font-family: $font;
  font-weight: $regular;
  padding: 1rem 3rem 1rem 1rem;
  font-size: 1rem;
  line-height: 1.25rem;
  transition: transform 0.2s;
  background-color: $white;
  &:hover {
    box-shadow: inset 0 0 0 1px $gray-light;
  }
  &:focus {
    border: 1px solid $blue;
    outline: none;
    box-shadow: inset 0 0 0 1px $blue;
  }
  &:disabled {
    cursor: not-allowed;
    color: $gray;
    background-color: $gray-lightest;
  }
  .requireReview {
    background-color: $bg-fail;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

.custom_select_input input {
  box-shadow: none;
}

select {
  display: block;
  width: 100%;
  padding: 9px 6px;
}

.deleted_gradient {
  background: repeating-linear-gradient(
    -20deg,
    $bg-attention,
    $bg-attention 10px,
    rgb(253, 239, 195, 0.7) 10px,
    rgb(253, 239, 195, 0.7) 30px
  );
}

.read_only {
  label {
    color: $gray;
  }
  input,
  textarea,
  select {
    padding: 6px 0;
    color: $black;
    border: 0;
    border-radius: 0;
    cursor: not-allowed;
    pointer-events: none;
    touch-action: none;
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 0px transparent;
  }
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  textarea {
    resize: none;
  }
  ::placeholder {
    color: transparent;
  }
}

// Grid ////////////////////////////////////////////////////
// Grid columns
.grid_container {
  max-width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-gap: 32px;
  padding: 0 15px;
  margin: auto;
  grid-template-columns: 1fr;
  // Above 1024px
  @media (min-width: breakpoints(large)) {
    max-width: 960px;
    padding-left: 15px;
    padding: unset;
  }
  // Above 1200px
  @media (min-width: breakpoints(extra-large)) {
    max-width: 90vw;
    padding: 0;
  }

  // Above 2000px
  @media (min-width: breakpoints(extra-extra-large)) {
    max-width: 1800px;
    padding: 0;
  }

  &.two_columns {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px 16px;
    // Mobile
    @media (max-width: breakpoints(large)) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }
  }

  &.three_columns {
    grid-template-columns: repeat(3, 1fr);
    // Mobile
    @media (max-width: breakpoints(large)) {
      grid-template-columns: 1fr;
    }
  }

  &.four_columns {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: breakpoints('extra-large')) {
      grid-template-columns: repeat(2, 1fr);
    }
    // Mobile
    @media (max-width: breakpoints(large)) {
      grid-template-columns: 2fr;
    }
  }

  &.gutterless {
    grid-gap: 0;
  }
}

.use-cases,
.verticals {
  grid-gap: 0px 16px !important;
  margin-bottom: 15px;
}

// Icons //////////////////////////////////////////////////
.icon {
  width: 24px;
  height: 24px;

  &.filter {
    path {
      fill: $blue;
    }
  }
}

// Global structure (Container, Header, Footer) ///////////
.main_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  // Global Header
  .main_header {
    box-sizing: border-box;
    height: 90px;
    background-color: $gray-lightest;
    border-bottom: 7px solid $red;
    font-family: $font, sans-serif;
    font-weight: $light;
    flex-shrink: 0;
    @media (max-width: breakpoints(large)) {
      height: unset;
    }
  }

  .main_content {
    flex-grow: 1;
  }

  // Global Footer
  .main_footer {
    flex-shrink: 0;
    display: block;
    overflow: hidden;
    @media (min-width: breakpoints(large)) {
      @media (min-width: breakpoints(large)) {
        background: linear-gradient(to right, #e2e3e4 70%, #97999b 0%);
      }
    }
  }
}

.main_header,
.beta_version_footer,
.main_footer {
  flex-shrink: 0;
}

// Sticky behaviour
.sticky {
  position: sticky;
  top: 16px;
}

.ui.multiple.search.dropdown {
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08) !important;
  border-radius: 4px !important;
  border: none !important;
  padding-left: 2.5em !important;
  padding-top: 1.2em !important;
  width: auto;
  height: max-content;
  & i {
    left: 1em;
    top: 1.5em !important;
    right: unset !important;
  }
  & > .text {
    font-family: $font !important;
    color: $gray-dark !important;
  }
  & > input.search {
    font-family: $font !important;
    color: $gray-dark !important;
  }
  & > .label {
    color: $white;
    border-radius: 4px;
    background-color: $blue-darker;
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    box-shadow: none;
  }

  &.main_page_first {
    width: 45% !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02),
      inset -0.900852px -0.900852px 0px #f1f1f5 !important;
  }
  &.main_page_second {
    width: 45% !important;
    border-radius: 0px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02),
      inset -0.900852px -0.900852px 0px #f1f1f5 !important;
  }
}

.ui.selection.active.dropdown .menu {
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 14px 24px rgba(51, 51, 51, 0.12);
  border-radius: 4px;
  border-color: transparent;
  margin-top: 4px;
  &:hover {
    border-color: transparent;
  }
  bottom: auto;
  top: 100%;
}

p:last-child {
  margin-bottom: 1em;
  margin-top: 1em;
}

label:last-child {
  margin-bottom: unset !important;
  margin-top: unset !important;
}

.ui.selection.active.dropdown:hover .menu {
  border-color: transparent;
}

// Beta Version footer
.beta_version_footer {
  width: 100%;
  padding: 24px 0 32px 0;
  margin: 0 auto;
  background-color: #7b7b7b;
}

// Default Table
.default_table {
  width: 100%;
  margin: 32px 0 10px;
  border-collapse: collapse;

  thead {
    font-size: 14px;
    font-weight: $medium;
    line-height: 16px;
    vertical-align: bottom;
    th {
      text-align: left;

      padding: 16px 12px;
    }
  }

  tbody {
    font-size: 14px;
    font-weight: $light;
    tr {
      border-bottom: 1px solid $gray-lighter;
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        td,
        th {
          background-color: $light-grayish-blue;
        }
      }
    }
    th,
    td {
      padding: 12px;
      background-color: $white;
    }
  }

  .hidden {
    &::before {
      content: '';
      display: inline-flex;
      width: 12px;
      height: 12px;
      margin-right: 8px;
      background-color: $red;
      border-radius: 50%;
    }
  }
  .visible {
    &::before {
      content: '';
      display: inline-flex;
      width: 12px;
      height: 12px;
      margin-right: 8px;
      background-color: $green;
      border-radius: 50%;
    }
  }
}

.grid_services {
  max-width: 100%;
  box-sizing: border-box;
  display: grid;
  padding: 0 15px;
  margin: auto;
  grid-template-columns: 1fr;
  // Above 1024px
  @media (min-width: breakpoints(large)) {
    max-width: 960px;
    padding-left: 15px;
    padding: unset;
  }
  // Above 1200px
  @media (min-width: breakpoints(extra-large)) {
    max-width: 90vw;
    padding: 0;
  }

  // Above 2000px
  @media (min-width: breakpoints(extra-extra-large)) {
    max-width: 1800px;
    padding: 0;
  }

  &.two_columns {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px 16px;
    // Mobile
    @media (max-width: breakpoints(large)) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }
  }

  &.three_columns {
    grid-template-columns: repeat(3, 1fr);
    // Mobile
    @media (max-width: breakpoints(large)) {
      grid-template-columns: 1fr;
    }
  }

  &.four_columns {
    grid-template-columns: repeat(4, 1fr);
    // Mobile
    @media (max-width: breakpoints(large)) {
      grid-template-columns: 1fr;
    }
  }

  &.gutterless {
    grid-gap: 0;
  }
}

.custom-calendar {
  height: 400px !important;
}

.custom_blue_scrollbar {
  &::-webkit-scrollbar-track {
    background: #fafafa;
    border-left: 0.5px solid #e8e8e8;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #2360a0;
    border-radius: 20px;
    border: 4px solid transparent;
    background-clip: content-box;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #1f384c;
    border-radius: 20px;
    border: 4px solid transparent;
    background-clip: content-box;
  }
}
