@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root,
  .dark {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;

    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;

    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;

    --border: 220 13% 91%;
    --input: 220 13% 91%;

    --primary: 211 64% 38%;
    --primary-hover: 211 64% 33%;
    --primary-foreground: 210 20% 98%;

    --secondary: 0 0% 100%;
    --secondary-foreground: 220.9 39.3% 11%;

    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;

    --ring: 217.9 10.6% 64.9%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
    font-family: var(--gotham-font);
  }
  body {
    @apply bg-background text-foreground;
  }
}
