///////////////////////////////////////////////////////////
// Main Header                               (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.header_nav {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  align-self: center;
}

.menu_btn {
  display: none;
  position: relative;
  margin-top: 8px;
  flex-direction: column;
  align-items: center;
  width: 28px;
  &:hover {
    cursor: pointer;
  }
  // Mobile
  @media (max-width: breakpoints(large)) {
    display: flex;
  }
  span {
    display: block;
    width: 26px;
    height: 22px;
    background: $gray-lightest url("../../../public/images/icon-menu.svg") no-repeat center;
  }
  small {
    font-size: .6rem;
    text-transform: uppercase;
    font-weight: $bold;
    color: $red;
    margin-top: 6px;
    letter-spacing: -.5px;
  }
  // Open Menu
  &.open {
    span {
      background: $red url("../../../public/images/icon-cross.svg") no-repeat center;
      border-radius: 4px;
    }
  }
}

.logo_nav {
  // Mobile
  @media (max-width: breakpoints(large)) {
    flex-grow: 1;
    margin-left: -26px;
  }
  a {
    display: block;
    width: 205px;
    height: 47px;
    box-sizing: border-box;
    margin-right: 10px;
    background-image: url("../../../public/images/broadcom-logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 205px auto;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    // Mobile
    @media (max-width: breakpoints(large)) {
      margin: 0 auto;
    }
  }
}

.menu_wrapper {
  box-sizing: border-box;
  width: 100%;
  // Mobile
  @media (max-width: breakpoints(large)) {
    position: absolute;
    top: 72px;
    left: -15px;
    width: calc(100% + 30px);
    background-color: $gray-dark;
    flex-direction: column;
    z-index: 2;
  }
}

.header_menu {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  z-index: 2;
  li { padding-top: 2px; }
  li a {
    box-sizing: border-box;
    text-decoration: none;
    color: $red;
    font-size: 1rem;
    font-weight: $light;
    margin: 0 10px;
  }
  .login_nav {
    z-index: 2;
    margin-left: auto;
    a {
      font-size: 14px;
      color: $gray;
    }
  }
  // Mobile
  @media (max-width: breakpoints(large)) {
    display: none;
    flex-direction: column;
    padding-top: 9px;
    padding-right: 4px;
    margin-bottom: 32px;
    &.open {
      display: flex;
    }
    li a {
      display: block;
      text-align: center;
      box-sizing: border-box;
      width: calc(100% - 16px);
      font-weight: $regular;
      line-height: 1.15rem;
      background-color: $gray-lightest;
      border-radius: 4px;
      padding: 12px 0 8px 0;
      margin: 7px auto;
    }
    .login_nav {
      margin: 14px 0 0 4px;
      a {
        border-bottom: 2px solid $black;
        border-radius: 0;
        background-color: $gray-darkest;
        font-size: 16px;
        color: $white;
        font-weight: $medium;
        text-align: left;
        padding: 16px 10px 14px 10px;
      }
    }
  }
}
